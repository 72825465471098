@import '../../../constants';

.mobile {
  width: 100%;
  height: 100vh;
  background-image: url(../../../assets/bg2-20.jpg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow: hidden;
  background-position:25% 50%;

  .container {
    width: 100%;
    height: 100%;
  
    @include blueGradientRGBA();

    .icon {
        position: absolute;
        top: 7rem;
        left: 50%;
        transform: translateX(-50%);
        width: 7.2rem;

        &.ipad {
           width: 27%;     
        }
    }
    .logo {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 70%;
        transform: translate(-50%, -50%);
    }
    
    .email-container {
        position: absolute;
        bottom: 15%;
        width: 100%;
        .join {
            position: relative;
            width: 8.3rem;
            left: 50%;
            transform: translateX(-50%);

        }
        .email {
            position: relative;
            left: 50%;
            transform: translateX(-50%);
    
            text-align: center;
            width: 65%;
    
            input {
                border-radius: 5px;
                height: 1.5rem;
                width: 100%;
            }
    
            button {
                position: relative;;
                display: block;
                background: $red;
                color: #ffffff;
                border: none;
                border-radius: 32px;
                font-size: 1rem;
                padding: .7rem 1.3rem .7rem;
                margin: 0 auto;
                margin-top: 1rem;
            }
            .msg-alert {
                position: absolute;
                width: 100%;
                margin: 0 auto;
                p {
                  color: white;
                }
            }
        }
    }
  }
  &.portrait {
    .container {
        .logo, .email-container {
            display: none;
        }
    }
  }
}
