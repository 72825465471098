$purple: #1b1237;
$darkBlue: #162251;
$lightBlue: #132a57;
$lightPurple: #212a73;
$red: #ff2053;
$darkRed: #eb0037;
$darkGreen: #08b240;
$green: #00ff24;
$gray: #333333;
$white: #ffffff;
$offWhite: #f3f3f3;
$black: #020202;

@mixin blueGradientMain {
    background: rgb(27,18,55);
    background: linear-gradient(-55deg, rgba(27,18,55,1) 0%, rgba(22,34,81,1) 35%, rgba(33,42,115,1) 100%);
}

@mixin  blueGradientRGBA {
    background: rgb(27,18,55);
    background: linear-gradient(-55deg, rgba(27,18,55,.9) 0%, rgba(22,34,81,.7) 35%, rgba(33,42,115,.9) 100%);
}

@mixin zuesShadows {
    -webkit-box-shadow: 0px 0px 33px 0px rgba(8, 178, 64, 1);
    -moz-box-shadow: 0px 0px 33px 0px rgba(8, 178, 64, 1);
    box-shadow: 0px 0px 33px 0px rgba(8, 178, 64, 1);
}

@mixin commonShadows {
    -webkit-box-shadow: 0px 0px 17px 2px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 0px 17px 2px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 0px 17px 2px rgba(0, 0, 0, 0.55);
}

@mixin commonShadowsRight {
    -webkit-box-shadow: -1px 0px 17px 2px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: -1px 0px 17px 2px rgba(0, 0, 0, 0.55);
    box-shadow: -1px 0px 17px 2px rgba(0, 0, 0, 0.55);
}

@mixin commonShadowsLeft {
    -webkit-box-shadow: 1px 0px 17px 2px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 1px 0px 17px 2px rgba(0, 0, 0, 0.55);
    box-shadow: 1px 0px 17px 2px rgba(0, 0, 0, 0.55);
}

@mixin offWhiteGradient {
    background: rgb(255, 255, 255);
    background: linear-gradient(
      355deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(243, 243, 243, 1) 55%,
      rgba(204, 204, 204, 1) 91%
    );
}

@mixin btn-green-hover {
    &:hover {
        background-color: $purple;
        -webkit-box-shadow: 0px 0px 5px 0px rgba(8,178,64,1);
        -moz-box-shadow: 0px 0px 5px 0px rgba(8,178,64,1);
        box-shadow: 0px 0px 5px 0px rgba(8,178,64,1);
    }
}